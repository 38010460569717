import { useMutation, useQuery } from '@tanstack/vue-query';
import { HTTPMethod } from 'h3';
import { defineStore } from 'pinia';
import { Role } from '~/composables/types/Role';
import { generateFilter, globalFetchOptions } from '~/utils/helpers';
import { ServiceArea, ServiceAreaFilterView } from '~~/composables/types/ServiceArea';
import { ColumnFilter, FilterOptions, Pagination, Sort } from '../types/Common';
import { ApplicationLimitConfiguration } from './../types/ServiceArea';
import { ChildLocation } from '../types/Location';
import { SavePersonalDetails } from '~/composables/types/PersonalDetails';

export const useServiceAreaStore = defineStore('service_areas', () => {
  const filters = ref({
    name: [
      {
        value: '',
        operator: 'Contains',
        name: 'Name',
      },
    ],
    zipCodes: [
      {
        value: '',
        operator: 'ContainsAny',
        name: 'Zipcode',
      },
    ],
    active: [
      {
        value: '',
        operator: 'IsEqualTo',
        name: 'Active',
        valueLabel: [],
      },
    ],
  });
  const columns = ref({
    name: true,
    zipCodes: true,
  });
  const filterOptions = ref([]);
  const getAll = (filter: FilterOptions, pagination: Pagination, sort: Sort) => {
    return $fetch<{ success: boolean; data: ServiceArea[]; count: number }>(
      `/service-areas?${generateFilter(filter)}page=${pagination.currentPage}&pageSize=${pagination.perPage}&sort=${
        sort.id
      }&order=${sort.desc ? 'desc' : 'asc'}`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };
  const getAllFromView = (ignoreSAHelpPerms = false) => {
    const fetcher = async () =>
      $fetch<{ success: boolean; data: { result: ServiceAreaFilterView[] }; count: number }>(
        `/service-areas?ignoreSAHelpPerms=${ignoreSAHelpPerms}&page=1&pageSize=1000&sort=name&order=asc`,
        {
          ...globalFetchOptions(),
          method: 'get',
        }
      );

    return useQuery({ queryKey: ['service-area-for-permission'], queryFn: fetcher, ...globalUseQueryOptions() });
  };
  const getAllForSelect = () => {
    const fetcher = async () =>
      $fetch<{ success: boolean; data: { result: ServiceArea[] }; count: number }>(
        `/service-areas?page=1&pageSize=1000&sort=name&order=asc`,
        {
          ...globalFetchOptions(),
          method: 'get',
        }
      );

    return useQuery({ queryKey: ['service-area-for-select'], queryFn: fetcher, ...globalUseQueryOptions() });
  };

  const getFetchAllForSelect = () => {
    return $fetch<{ success: boolean; data: { result: ServiceArea[] }; count: number }>(
      `/service-areas?page=1&pageSize=1000&sort=name&order=asc`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };

  const create = () => {
    return useMutation((formData: ServiceArea) =>
      $fetch<{ success: true; data: ServiceArea }>(`/service-areas`, {
        ...globalFetchOptions(),
        method: 'POST',
        body: formData,
      })
    );
  };

  const edit = (id: string) => {
    return useMutation((formData: ServiceArea) =>
      $fetch<{ success: true; data: ServiceArea }>(`/service-areas/${id}`, {
        ...globalFetchOptions(),
        method: 'PUT',
        body: formData,
      })
    );
  };

  const getById = (id: string) => {
    const fetcher = async () =>
      $fetch<{ success: boolean; data: ServiceArea }>(`/service-areas/${id}`, {
        ...globalFetchOptions(),
        method: 'get',
      });

    return useQuery({ queryKey: [`service-area-by-id-${id}`], queryFn: fetcher, ...globalUseQueryOptions() });
  };
  const editMeetingSite = (serviceAreaId: string, meetingSiteId: string, method: HTTPMethod) => {
    return $fetch<{ success: boolean; data: ServiceArea }>(
      `/service-areas/${serviceAreaId}/meeting-sites/${meetingSiteId}`,
      {
        ...globalFetchOptions(),
        method: method,
      }
    );
  };

  const getRoles = () => {
    return $fetch<{ success: boolean; data: Role[] }>(`/service-areas/roles`, {
      ...globalFetchOptions(),
      method: 'get',
    });
  };

  const getRolesForServiceArea = (serviceAreaId: string) => {
    return $fetch<{ success: boolean; data: Role[] }>(`/service-areas/${serviceAreaId}/roles`, {
      ...globalFetchOptions(),
      method: 'get',
    });
  };

  const getRolesForServiceAreaWithAvailability = (serviceAreaId: string) => {
    console.log('getRolesForServiceAreaWithAvailability');
    return $fetch<{ success: boolean; data: Role[] }>(
      `/service-areas/${serviceAreaId}/roles?checkAvailable=true&removePaymentProcessing=true`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };

  const getRolesAssignedToAr = () => {
    return $fetch<{ success: boolean; data: Role[] }>(`/assistance-requests/roles`, {
      ...globalFetchOptions(),
      method: 'get',
    });
  };

  const getServiceAreaRole = (removePaymentProcessing = true) => {
    let url = `/service-areas/roles/all`;
    if (!removePaymentProcessing) {
      url += `?removePaymentProcessing=false`;
    }
    return $fetch<{ success: boolean; data: Role[] }>(url, {
      ...globalFetchOptions(),
      method: 'get',
    });
  };

  const getPermissions = () => {
    return $fetch<{ success: boolean; data: Role[] }>(`/service-areas/permissions`, {
      ...globalFetchOptions(),
      method: 'get',
    });
  };

  const getLanguagesData = (
    id: string,
    languageId: string,
    keys = 'welcome_title,welcome_message,new_application_message,default_appointment_message,question,default_payment_message'
  ) => {
    return $fetch<{ success: boolean; data: Role[] }>(`/service-areas/${id}/translations/${languageId}?keys=${keys}`, {
      ...globalFetchOptions(),
      method: 'get',
    });
  };

  const editPermission = (serviceAreaId: string, roleId: number, permissionId: number, method: HTTPMethod) => {
    return $fetch<{ success: boolean; data: ServiceArea }>(
      `/service-areas/${serviceAreaId}/roles/${roleId}/permissions/${permissionId}`,
      {
        ...globalFetchOptions(),
        method: method,
      }
    );
  };

  const setFilters = (f: { query?: { name?: string; zipCodes?: string; active?: string } }) => {
    filters.value.name[0].value = f.query?.name ?? '';
    filters.value.zipCodes[0].value = f.query?.zipCodes ?? '';
    filters.value.active[0].value = f.query?.active && typeof f.query.active === 'string' ? f.query.active : '';
    // @ts-ignore
    filters.value.active[0].valueLabel = f.query?.active === 'true' ? ['Active'] : ['Inactive'];
  };

  const openFilterModel = ref(false);
  const setOpenFilterModel = (value: boolean) => {
    openFilterModel.value = value;
  };

  const exportCsv = async (filter: FilterOptions, sort: Sort, column: ColumnFilter) => {
    return $fetch<Blob>(
      `/service-areas?${generateFilter(filter)}sort=${sort.id}&order=${sort.desc ? 'desc' : 'asc'}&${generateColumns(
        column
      )}`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };

  const updateRestriction = (id: string) => {
    return useMutation((formData: ApplicationLimitConfiguration | null) =>
      $fetch<{ success: true; data: { message: string; code: string } }>(`/service-areas/${id}/restriction`, {
        ...globalFetchOptions(),
        method: 'PUT',
        body: formData,
      })
    );
  };

  const willLimitBeExceeded = (id: string) => {
    return useMutation((formData: ApplicationLimitConfiguration | null) =>
      $fetch<{
        success: boolean;
        data: {
          showWarning: boolean;
          willExceed: boolean;
          serviceAreaId: string;
        };
      }>(
        `/service-areas/${id}/restriction-exceeded?enableWeekly=${formData?.enableWeekly}&weeklySelectedDate=${
          formData?.weeklySelectedDate ?? ''
        }${
          formData?.weeklyTotalApplications !== null &&
          formData?.weeklyTotalApplications !== undefined &&
          formData?.weeklyTotalApplications > 0
            ? '&weeklyTotalApplications=' + formData?.weeklyTotalApplications
            : ''
        }&enableMonthly=${formData?.enableMonthly}&monthlySelectedDate=${formData?.monthlySelectedDate ?? ''}
      ${
        formData?.monthlyTotalApplications !== null &&
        formData?.monthlyTotalApplications !== undefined &&
        formData?.monthlyTotalApplications > 0
          ? '&monthlyTotalApplications=' + formData?.monthlyTotalApplications
          : ''
      }`,
        {
          ...globalFetchOptions(),
          method: 'GET',
        }
      )
    );
  };

  const CheckSubmittedCountForRestriction = (id: string) => {
    const fetcher = async () =>
      $fetch<{
        success: boolean;
        data: {
          serviceAreaId: string;
          weeklyCount: number;
          monthlyCount: number;
        };
      }>(`/service-areas/${id}/restriction-count`, {
        ...globalFetchOptions(),
        method: 'get',
      });

    return useQuery({ queryKey: [`submitted-count-for-restriction-${id}`], queryFn: fetcher, ...globalUseQueryOptions() });
  };

  const transferRemoveZipcode = (id: string, zipcode: string) => {
    return useMutation((formData: { action: string; zipcode: string; targetServiceAreaId?: string }) =>
      $fetch<{ success: true; data: { message: string; code: string } }>(`/service-areas/${id}/transfer-remove-zipcode`, {
        ...globalFetchOptions(),
        method: 'PUT',
        body: formData,
      })
    );
  };

  const checkRemovedZipcode = () => {
    return useMutation((formData: { zipcode: string }) =>
      $fetch<{
        success: boolean;
        data: {
          removed: boolean;
          serviceAreaId: string;
          serviceAreaName: string;
        };
      }>(`/service-areas/${formData.zipcode}/check-zip-removed`, {
        ...globalFetchOptions(),
        method: 'GET',
      })
    );
  };

  const getChildLocations = () => {
    return useMutation((licensedLocationId: number) =>
      $fetch<{
        success: boolean;
        data: ChildLocation[];
      }>(`/service-areas/locations/${licensedLocationId}`, {
        ...globalFetchOptions(),
        method: 'GET',
      })
    );
  };

  const getChangedId = () => {
    return useMutation((zipcode: string) =>
      $fetch<{ success: boolean; data: ServiceArea }>(`/service-areas/${zipcode}/get-details`, {
        ...globalFetchOptions(),
        method: 'GET',
        retry: false,
      })
    );
  };

  const saveTranslation = (serviceAreaId: string, languageId: string) => {
    return useMutation((formData: { key: string; value: string }[]) => {
      return $fetch<SavePersonalDetails>(`/service-areas/${serviceAreaId}/translations/${languageId}`, {
        ...globalFetchOptions(),
        method: 'POST',
        body: formData,
        retry: false,
      });
    });
  };

  const saveLanguage = (serviceAreaId: string, languageId: string) => {
    return useMutation((formData: { enabled: boolean }) => {
      return $fetch<SavePersonalDetails>(
        `/service-areas/${serviceAreaId}/languages/${languageId}?enable=${formData.enabled}`,
        {
          ...globalFetchOptions(),
          method: 'POST',
          retry: false,
        }
      );
    });
  };

  const getLanguage = (serviceAreaId: string) => {
    return $fetch<{ data: { languageID: string; enabled: boolean }[] }>(`/service-areas/${serviceAreaId}/languages`, {
      ...globalFetchOptions(),
      method: 'GET',
      retry: false,
    });
  };

  const getZipCodeAssistanceType = (zipcode: string, service_area_id: string) => {
    const fetcher = async () =>
      $fetch<{ success: boolean; data: any[]; count: number }>(
        `/assistance-requests/get-assistance-types/${zipcode}?serviceAreaId=${service_area_id}`,
        {
          ...globalFetchOptions(),
          method: 'get',
        }
      );

    return useQuery({ queryKey: ['zip-code-at'], queryFn: fetcher, ...globalUseQueryOptions() });
  };

  return {
    getAll,
    create,
    edit,
    getById,
    filters,
    editMeetingSite,
    getRoles,
    getRolesForServiceArea,
    getRolesForServiceAreaWithAvailability,
    getPermissions,
    editPermission,
    setFilters,
    getAllForSelect,
    getAllFromView,
    openFilterModel,
    setOpenFilterModel,
    exportCsv,
    columns,
    getRolesAssignedToAr,
    getFetchAllForSelect,
    updateRestriction,
    transferRemoveZipcode,
    checkRemovedZipcode,
    willLimitBeExceeded,
    CheckSubmittedCountForRestriction,
    getServiceAreaRole,
    getChildLocations,
    getChangedId,
    getLanguagesData,
    saveTranslation,
    saveLanguage,
    getLanguage,
    filterOptions,
    getZipCodeAssistanceType,
  };
});
